import 'twin.macro'
import * as React from 'react'
import { map } from 'lodash'
import dayjs from 'dayjs'
import { useState } from 'react'
import { Button, Container, Header, Image, Modal } from 'semantic-ui-react'
import { StaticImage } from 'gatsby-plugin-image'
import { useEffect } from 'react'
import ImageZoom from 'react-medium-image-zoom'
import { navigate } from 'gatsby'
import { motion } from 'framer-motion'

import Layout from '../components/Layouts/cakeDefault'
import fire from '../assets/svg/fire.svg'
import { buttonVariants } from '../utils/motion'

const voucherTypesOld = [
  {
    name: 'Giảm 30k khi mua bánh kem/mousse hoa quả tươi',
    voucherType: 'DISCOUNT_CAKE_ORDER',
    image: (
      <StaticImage
        src="../assets/images/get-voucher/voucher-sale-30k-fruit.jpg"
        alt="Giảm 30k khi mua bánh kem/mousse hoa quả tươi"
        style={{
          maxWidth: '100%',
          maxHeight: '100%',
          objectFit: 'cover',
          borderRadius: '5%',
        }}
      />
    ),
  },
]

const voucherTypes = [
  {
    name: 'Giảm 10% tổng hóa đơn cho đơn hàng bánh sinh nhật tiếp theo',
    voucherType: 'DISCOUNT_CAKE_ORDER_10_PERCENT',
    image: (
      <StaticImage
        src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-bsn/policy/get-voucher-10%25.jpg"
        alt="Voucher giảm giá bánh sinh nhật"
        style={{
          maxWidth: '100%',
          maxHeight: '100%',
          objectFit: 'cover',
          borderRadius: '5%',
        }}
      />
    ),
  },
]

export default function GetVoucherPage() {
  const [valid, setValid] = useState(true)
  const [voucherTokenId, setVoucherTokenId] = useState(null)
  const [fetching, setFetching] = useState(true)
  const [error, setError] = useState('')
  const [selectedVoucherType, setSelectedVoucherType] = useState(null)
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    // get query voucherToken
    const query = new URLSearchParams(window.location.search)
    const voucherToken = query.get('voucherToken')
    if (!voucherToken) {
      setValid(false)
      setError('Mã quét để lấy voucher không hợp lệ')
      return
    }
    setVoucherTokenId(voucherToken)
    async function getVoucher() {
      const data = await fetch(
        `${process.env.GATSBY_API_URL}/voucher-tokens/${voucherToken}`
      )
      if (data.status >= 400) {
        setValid(false)
        setError('Voucher token không hợp lệ')
        return
      }
      const json = await data.json()
      console.log({ json })
      if (!json || !json._id) {
        setValid(false)
        setError('Không nhận được dữ liệu về voucher token')
        return
      }
      const now = dayjs()
      const limitShowTime = dayjs(json.usedDate).add(1, 'week')
      if (json.voucher) {
        setValid(false)
        if (now.isBefore(limitShowTime)) {
          navigate('/voucher?couponCode=' + json.voucher.couponCode)
        } else {
          setError(
            'Voucher token nãy đã được sử dụng rồi. Vui lòng liên hệ qua fanpage Savor để được hỗ trợ thêm nhé.'
          )
        }

        return
      }
      const expiryDate = dayjs(json.expiryDate)
      console.log({
        expiryDate,
        now,
      })
      if (now.isAfter(expiryDate)) {
        setValid(false)
        setError(
          'Voucher đã hết hạn vào ngày ' + expiryDate.format('DD/MM/YYYY')
        )
        return
      }

      setValid(true)
    }
    getVoucher()
      .catch((err) => {
        console.log(err)
        setValid(false)
        setError(err.message)
      })
      .finally(() => {
        setFetching(false)
      })
  }, [])

  const getVoucherFromToken = async () => {
    setSubmitting(true)
    try {
      if (!voucherTokenId) {
        return
      }
      const url = `${process.env.GATSBY_API_URL}/voucher-tokens/${voucherTokenId}/get-voucher`
      // send a POST to url with voucherType
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          voucherType: selectedVoucherType.voucherType,
        }),
      })

      const json = await response.json()
      console.log({ json })
      navigate('/voucher?couponCode=' + json.couponCode)
    } catch (e) {
      alert(`Gặp lỗi khi tạo voucher!`)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Layout endPoint={'get-voucher'} title={'Savor Cake | Get Voucher'}>
      <Modal
        onClose={() => setSelectedVoucherType(null)}
        open={!!selectedVoucherType}
      >
        <Modal.Header>Bạn chắc chắn chứ?</Modal.Header>
        <Modal.Content image>
          <Image size="medium" wrapped>
            <ImageZoom>
              {selectedVoucherType && selectedVoucherType.image}
            </ImageZoom>
          </Image>
          <Modal.Description>
            <Header>Bạn chắc chắn muốn chọn voucher này chứ?</Header>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content="Xác nhận"
            labelPosition="right"
            icon="checkmark"
            disabled={submitting}
            onClick={() => {
              getVoucherFromToken(selectedVoucherType)
            }}
            positive
          />
          <Button
            color="black"
            disabled={submitting}
            onClick={() => {
              setSelectedVoucherType(null)
            }}
          >
            Quay lại
          </Button>
        </Modal.Actions>
      </Modal>

      {fetching ? (
        <Container style={{ marginTop: 20 }}>
          <Header as="h1">Đang tải dữ liệu...</Header>
        </Container>
      ) : (
        <>
          {valid && (
            <Container style={{ marginTop: 20 }}>
              <h1
                tw="text-center uppercase text-savor-cake-primary-green-avocado-0 font-bold
                px-8 md:px-0 leading-tight! text-2xl! md:text-4xl!"
              >
                <Image
                  src={fire}
                  alt="fire"
                  style={{
                    display: 'inline-block',
                    height: 'auto',
                    verticalAlign: 'middle',
                    width: '2em',
                    marginRight: '0.5em',
                  }}
                />
                Đổi voucher bánh sinh nhật ngay
              </h1>

              <div tw="flex justify-center items-center mx-auto">
                {map(
                  dayjs().isBefore(dayjs('2024-01-01'))
                    ? voucherTypesOld
                    : voucherTypes,
                  (voucherType) => {
                    return (
                      <motion.div
                        variants={buttonVariants}
                        whileHover="hover"
                        key={voucherType.name}
                      >
                        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                        <div
                          tabIndex="-1"
                          role="button"
                          onClick={() => setSelectedVoucherType(voucherType)}
                          tw="flex justify-center items-center cursor-pointer mx-auto"
                        >
                          {voucherType.image}
                        </div>
                      </motion.div>
                    )
                  }
                )}
              </div>
            </Container>
          )}

          {!valid && (
            <Container style={{ marginTop: 20 }}>
              <Header
                as="h1"
                style={{
                  textAlign: 'center',
                  textTransform: 'uppercase',
                  color: '#61A05C',
                }}
              >
                Xin lỗi! Mã voucher không khả dụng
              </Header>
              <Header
                as="h2"
                style={{
                  textAlign: 'center',
                  textTransform: 'uppercase',
                  color: '#61A05C',
                }}
              >
                Lý do: {error}
              </Header>
            </Container>
          )}
        </>
      )}
    </Layout>
  )
}
